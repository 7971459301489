import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private baseUrl: string = environment.apiURL;

  constructor(
    private http: HttpClient
  ) { }

  // getCountries() {
  //   const url = `${this.baseUrl}/`;
  //   return this.http.get(url);
  // }

  generateOTP(mobileNumber: number, UUID: string, userId?: number): Observable<any> {
    let url: any;
    if(typeof userId !== 'undefined'){
      url = `${this.baseUrl}/accesscontrol/Otp/generate?userId=${userId}`;
    }
    else{
      url = `${this.baseUrl}/accesscontrol/Otp/generate`;
    }
    const payload = {
      mobileNumber: mobileNumber,
      traceId: UUID,
      otpValue: ''
    }
    const httpOptions = {
      observe: 'response' as const
    }
    return this.http.post<any>(url, payload);
  }

  verifyMobileOTP(otp: number, mobileNumber: number, UUID: string): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Otp/verify`;
    const payload = {
      mobileNumber: mobileNumber,
      traceId: UUID,
      otpValue: otp
    }
    return this.http.post<any>(url, payload);
  }

  checkMailtoUse(email: string): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/${email}/canuse`;
    return this.http.post<any>(url, {});
  }

  registerAccount(formData: any): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account`;
    return this.http.post<any>(url, formData).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    );
  }

  getCountries(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.baseUrl}/accesscontrol/Country/all/`);
  }

  getPrivacyNotice(): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/privacyNoticeUrl`
    return this.http.get<any>(url).pipe(
        map(data => {
            return data['response'];
        })
    );
}

getPrivacyPolicy(): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/privacyPolicyUrl`
    return this.http.get<any>(url).pipe(
        map(data => {
            return data['response'];
        })
    );
}

getTermsAndConditions(): Observable<{ [key: string]: any }> {
    const url = `${this.baseUrl}/accesscontrol/Account/GetTermsAndCondition`
    return this.http.get<{ [key: string]: any }>(url).pipe(
        map(data => {
            return { termsAndConditions: data['response'] };
        })
    );
}

getCountry(): Observable<any> {
  return this.http.get<any>(`${this.baseUrl}/accesscontrol/Country/all`);
}

getBusinessType(): Observable<any> {
  return this.http.get<any>(`${this.baseUrl}/accesscontrol/UserProfile/BussinessTypes`);
}

getUserProfileDetails(userId: number): Observable<any> {
  return this.http.get<any>(`${this.baseUrl}/accesscontrol/UserProfile/UserProfileView/${userId}`);
}

addUserProfileDetails(body: any): Observable<any> {
  const url = `${this.baseUrl}/accesscontrol/UserProfile/update`
  return this.http.put<any>(url, body).pipe(catchError(error => of(error)));
}

//add User profile picture
updateUserProfilePicture(file: any, userId: number): Observable<any> {
  const formData = new FormData();
  formData.append('image', file);
  const url = `${this.baseUrl}/accesscontrol/UserProfile/profilePicUpdate/${userId}`
  return this.http.post<any>(url, formData).pipe(catchError(error => of(error)));
}

//delete User profile picture
deleteUserProfilePicture(userId: number): Observable<any> {
  const url = `${this.baseUrl}/accesscontrol/UserProfile/deleteProfilePic/${userId}`
  return this.http.delete<any>(url).pipe(catchError(error => of(error)));
}

}
