import { Component } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../core/helpers/authentication.service';
import { RegisterService } from './service/register.service';
import { Guid } from 'guid-typescript';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {
  public guid!: Guid;
  registerForm: FormGroup;
  submitted: boolean = false;
  showMobileOTP: boolean = false;
  errorMessage = '';
  errorGenerateOTP: string = '';
  errorVerifyOTP: string = '';
  verifiedMobileOTP: boolean = false;
  errorEmail: string = '';
  verifyEmail: boolean = false;
  emailLoader: boolean = false;
  emailChanged: boolean = false;
  accountMessage: string ='';
  accountMsgColor: boolean = true;
  showInlineLoader: boolean = false;
  private uuid: any;
  private federatedId!: string | null;
  countries!: Array<any>;
  termsURL: string = '';
  otpInProgress: boolean = false;
  display: any;

  private subscription: Subscription = new Subscription();
  privacyPolicy!: string;
  privacyNotice!: string;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    public auth: AuthenticationService,
    public sharedService: SharedService
  ) {
    const displayNameValidator: ValidatorFn = (formControl: AbstractControl): ValidationErrors | null => {
      const firstName = formControl.get('firstName');
      const lastName = formControl.get('lastName');
      return firstName?.value && lastName?.value && firstName.value.toLowerCase() === lastName.value.toLowerCase() ? {displayName: true} : null;
    }

    this.registerForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[A-Za-z]+$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[A-Za-z]+$')]],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      mobileNumberOTP: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
      emailId: ['', [Validators.required, Validators.email]],
      country: ['', Validators.required],
      hasAgreementSigned: [false, Validators.requiredTrue],
      hasNewsLetterSubscribed: [false]
    },{
      validators: displayNameValidator
    });
  }


  ngOnInit(): void {
    // this.registerService.getCountries();
    this.getCountries();
    this.emailId.valueChanges.subscribe(mailId => {
      this.verifyEmail = false;
      this.emailChanged = true;
      if (this.errorEmail) {
        this.errorEmail = '';
      }
    });

    this.registerForm.valueChanges.subscribe(data => {
      this.submitted = false;
    });

    this.getTermsOfUse();
    this.getPrivacyNotice();
    this.getPrivacyPolicy();
  }

  getTermsOfUse() {
    this.subscription.add(
      this.registerService.getTermsAndConditions().subscribe((data) => {
        this.termsURL = data['termsAndConditions'].uri;
      })
    )
  }

  getPrivacyPolicy() {
    this.subscription.add(
      this.registerService.getPrivacyPolicy().subscribe((data) => {
        this.privacyPolicy = data;
      })
    )
  }

  getPrivacyNotice() {
    this.subscription.add(
      this.registerService.getPrivacyNotice().subscribe((data) => {
        this.privacyNotice = data;
      })
    )
  }

  sendFormData() {
    this.showInlineLoader = true;
    const registerData = {
      createdOn: new Date().toISOString(),
      modifiedOn: new Date().toISOString(),
      createdBy: this.registerForm.value.emailId,
      modifiedBy: this.registerForm.value.emailId,
      firstName: this.registerForm.value.firstName,
      lastName: this.registerForm.value.lastName,
      mobileNumber: this.registerForm.getRawValue().mobileNumber,
      emailAddress: this.registerForm.value.emailId,
      displayName: this.registerForm.value.firstName + ' ' + this.registerForm.value.lastName,
      hasAgreementSigned: this.registerForm.value.hasAgreementSigned,
      hasNewsLetterSubscribed: this.registerForm.value.hasNewsLetterSubscribed,
      isActive: false,
      activationLink: '',
      federatedId: this.federatedId,
      roleId: 0,
      customerId: 0,
      country: this.registerForm.value.country,
      otp:this.registerForm.value.mobileNumberOTP,
      traceId: this.uuid 
    };
    this.subscription.add(this.registerService.registerAccount(registerData).subscribe(result => {
      this.submitted = true;
      this.showInlineLoader = false;
      this.accountMessage = result.message;
      this.accountMsgColor = result.response;
      this.sharedService.showSnackbar(1,this.accountMessage);
      this.registerForm.reset();
    }, error => {
      this.submitted = true;
      this.showInlineLoader = false;
      this.accountMessage = error.message;
      this.accountMsgColor = error.response;
      this.sharedService.showSnackbar(5,this.accountMessage);
    }));
  }

  get registerFormControls() {
    return this.registerForm.controls;
  }

  get emailId() {
    return this.registerForm.controls['emailId'] as FormControl
  }

  generateMobileOTP() {
    this.otpInProgress = true;
    this.timer(0.5);
    this.uuid = this.uuidv4();
    this.subscription.add(this.registerService.generateOTP(this.registerForm.value.mobileNumber, this.uuid).subscribe(data => {
      if (data.response && data.success) {
        
        this.showMobileOTP = true;
        this.errorGenerateOTP = '';
      } else {
       
        this.showMobileOTP = false;
        this.errorGenerateOTP = data.message
      }
    }));
  }

  checkMailtoUse() {
    if (!this.registerFormControls['emailId'].errors && this.emailChanged) {
      this.emailLoader = true;
      this.subscription.add(this.registerService.checkMailtoUse(this.registerForm.value.emailId).subscribe(data => {
        this.emailLoader = false;
        this.emailChanged = false;
        if (data.response.canUse) {
          this.federatedId = data.response.federatedId;
          this.errorEmail = '';
          this.verifyEmail = true;
        } else {
          this.errorEmail = data.message;
          this.verifyEmail = false;
        }
      }));
    }
  }

  verfiyMobileOTP() {
    const mobileNumberOTP = this.registerForm.value.mobileNumberOTP;
    const mobileNumber =  this.registerForm.value.mobileNumber
    this.subscription.add(this.registerService.verifyMobileOTP(mobileNumberOTP, mobileNumber, this.uuid).subscribe(result => {
      if (result.response && result.success) {
        this.verifiedMobileOTP = true;
        this.showMobileOTP = false;
        this.errorVerifyOTP = '';
        this.registerForm.get('mobileNumber')?.disable();
      } else {
        this.verifiedMobileOTP = false;
        this.errorVerifyOTP = result.message;
      }
    }));
  }

  goToLogin() {
    this.auth.login();
  }

  uuidv4() {
    this.guid = Guid.create();
    return this.guid.toJSON().value;
  }

  getCountries() {
    this.registerService.getCountries()
      .subscribe((data: any) => this.countries = data);
  }

  openTermsOfUse(url: string) {
    window.open(url, '_blank');
  }

  timer(minute: any) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 30;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.otpInProgress = false;
        // console.log("finished");
        clearInterval(timer);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
}

}
